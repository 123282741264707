import React from "react";
import { getIdLabelLine, getIdLabelNumber } from "../../helper";
import "./styles.css";

const SteperNumber = ({ stepNumber }) => {
  return (
    <div className="step-numbers-header-container">
      <div
        className="step-number-container"
        id={getIdLabelNumber(1, stepNumber)}
      >
        <span>1</span>
      </div>
      <div className="step-line-container">
        <div
          className="step-line"
          id={getIdLabelLine(1, stepNumber)}
        />
      </div>
      <div
        className="step-number-container"
        id={getIdLabelNumber(2, stepNumber)}
      >
        <span>2</span>
      </div>
      <div className="step-line-container">
        <div
          className="step-line"
          id={getIdLabelLine(2, stepNumber)}
        />
      </div>
      <div
        className="step-number-container"
        id={getIdLabelNumber(3, stepNumber)}
      >
        <span>3</span>
      </div>
      <div className="step-line-container">
        <div
          className="step-line"
          id={getIdLabelLine(3, stepNumber)}
        />
      </div>
      <div
        className="step-number-container"
        id={getIdLabelNumber(4, stepNumber)}
      >
        <span>4</span>
      </div>
    </div>
  );
};

export default SteperNumber;
