import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useHistory } from "react-router";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();

  const isAuth = () => {
    try {
      const uuid = localStorage.getItem("uuid");
      if (uuid) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      history.replace("/start");
    }
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth() ? <Component {...props} /> : <Redirect to="/start" />
      }
    />
  );
};

export default PrivateRoute;
