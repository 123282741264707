import React, { useEffect, useState } from "react";
import {
  getPlatforms,
  IonButton,
  IonCardSubtitle,
  IonCardTitle,
  IonGrid,
  IonImg,
  IonPage,
  IonText,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import "./Welcome.css";
import { useHistory } from "react-router";
import axios from "axios";

const Welcome = ({ match }) => {
  const history = useHistory();
  const [logo, setLogo] = useState("");
  const [present, dismiss] = useIonLoading();
  const [presentAlert] = useIonAlert();
  const [isAuthorized, setIsAuthorized] = useState(false);

  const getLogo = (isDark) => {
    /*isDark
      ? setLogo("assets/img/entreconsultas-logo-white.svg")
      : setLogo("assets/img/entreconsultas-logo.svg");
      */
      setLogo("assets/img/OBD_SIGN.png");
  };

  useEffect(() => {
    const [platform] = getPlatforms();
    if (platform === "desktop") return history.replace("/app/errorPlatform");

    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
    getLogo(prefersDark.matches);

    prefersDark.addListener((mediaQuery) => {
      getLogo(mediaQuery.matches);
    });

    const uuid = match.params.uuid;
    if (!uuid) {
      setIsAuthorized(false);
      presentAlert({
        header: "ADVERTENCIA",
        message:
          "El link ingresado no es válido. Por favor solicite un nuevo link.",
        buttons: ["Aceptar"],
        onDidDismiss: null,
      });
      return;
    }

    const validateUuid = async () => {
      present({
        message: "Securizando comunicación...",
      });
      setIsAuthorized(false);
      setTimeout(async () => {
        try {
          await axios.post(
            "https://obd.entreconsultas.com/api/SearchTransaction/",
            { uuid }
          );
          localStorage.setItem("uuid", uuid);
          setIsAuthorized(true);
          dismiss();
        } catch (error) {
          dismiss();
          presentAlert({
            header: "ADVERTENCIA",
            message:
              "El link ingresado no es válido. Por favor solicite un nuevo link.",
            buttons: ["Aceptar"],
            onDidDismiss: null,
          });
        }
      }, 2500);
    };
    validateUuid();

    return () => {
      prefersDark.removeListener((mediaQuery) => {
        getLogo(mediaQuery.matches);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonPage>
      <IonGrid className="container">
        <div className="ion-text-center card-container">
          <IonImg src="assets/img/identity-check.jpg" />
          <IonCardSubtitle>Bienvenido</IonCardSubtitle>
          <IonCardTitle>Onboarding Digital</IonCardTitle>
          {!isAuthorized && (
            <IonText color="dark">
              <p
                style={{
                  fontSize: "1.2em",
                  marginRight: 10,
                  color: "red",
                  fontWeight: 900,
                }}
              >
                Link no válido. Solicite un nuevo link para continuar
              </p>
            </IonText>
          )}
        </div>
        <div className="powered-by-container">
          <IonButton
            fill="solid"
            onClick={() => history.replace("/app/guide")}
            disabled={!isAuthorized}
          >
            Comenzar
          </IonButton>
          <IonText color="dark">
            <p style={{ fontSize: "0.7em", marginRight: 10 }}>Powered by</p>
          </IonText>
          <IonImg src={logo} style={{ width: "150px" }} />
        </div>
      </IonGrid>
    </IonPage>
  );
};

export default Welcome;
