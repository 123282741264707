import { IonButton, IonIcon } from "@ionic/react";
import { camera } from "ionicons/icons";
import React from "react";

const PhotoButton = ({ photos, currentStep, goNextStep, takePhoto }) => {
  const handleClick = () => {
    photos.filter((photo) => photo.name === currentStep.name)[0].base64
      ? goNextStep()
      : takePhoto(currentStep.name);
  };

  return (
    <div style={{ width: "100%" }}>
      <IonButton
        color="primary"
        fill="solid"
        expand="full"
        style={{ margin: 0, height: "60px", fontSize: "20px" }}
        onClick={handleClick}
      >
        <IonIcon slot="start" icon={camera} />
        <span>
          {photos.filter((photo) => photo.name === currentStep.name)[0].base64
            ? "Ver foto"
            : "Tomar foto"}
        </span>
      </IonButton>
    </div>
  );
};

export default PhotoButton;
