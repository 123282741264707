import { IonButton, IonIcon } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import React from "react";
import "./styles.css";

const ButtonSection = ({ currentStep, goNextStep }) => {
  return (
    <div className="button-section-container">
      <div>
        {/* <IonButton color="primary" fill="clear" onClick={() => rotate(90)}>
          <IonIcon slot="end" icon={arrowUndoCircleOutline} />
          <span>Rotar</span>
        </IonButton> */}
      </div>
      {currentStep.type === "photo" && (
        <IonButton
          color="primary"
          fill="solid"
          expand="full"
          style={{ margin: 0, height: "60px", fontSize: "20px" }}
          onClick={goNextStep}
        >
          <IonIcon slot="end" icon={chevronForwardOutline} />
          <span>{currentStep.id === 7 ? "Finalizar" : "Siguiente"}</span>
        </IonButton>
      )}
    </div>
  );
};

export default ButtonSection;
