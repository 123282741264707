export const INITIAL_PHOTOS = [
  {
    label: "FOTO DNI DE FRENTE",
    name: "dni_front",
    base64: "",
    webPath: "",
  },
  {
    label: "FOTO DNI DE ATRAS",
    name: "dni_back",
    base64: "",
    webPath: "",
  },
  {
    label: "FOTO ROSTRO",
    name: "selfie",
    base64: "",
    webPath: "",
  },
  {
    label: "FOTO ROSTRO GESTO",
    name: "selfie_gesture",
    base64: "",
    webPath: "",
  },
];

export const INITIAL_STEPS = [
  {
    id: 0,
    stepNumber: 1,
    currentStep: true,
    type: "guide",
    name: "dni_front",
    content: {
      title: {
        line1: "Foto DNI",
        line2: "FRENTE",
      },
      text: "VOLTEE SU TELÉFONO y colóquelo sobre la parte FRONTAL de su DNI",
    },
    image: "assets/img/dni-front.png",
  },
  {
    id: 1,
    stepNumber: 1,
    currentStep: false,
    type: "photo",
    name: "dni_front",
  },
  {
    id: 2,
    stepNumber: 2,
    currentStep: false,
    type: "guide",
    name: "dni_back",
    content: {
      title: {
        line1: "Foto DNI",
        line2: "ATRAS",
      },
      text: "VOLTEE SU TELÉFONO y colóquelo sobre la parte de ATRAS de su DNI",
    },
    image: "assets/img/dni-back.png",
  },
  {
    id: 3,
    stepNumber: 2,
    currentStep: false,
    type: "photo",
    name: "dni_back",
  },
  {
    id: 4,
    stepNumber: 3,
    currentStep: false,
    type: "guide",
    name: "selfie",
    content: {
      title: {
        line1: "Foto rostro",
        line2: "NEUTRAL",
      },
      text: "Procure que haya BUENA ILUMINACIÓN y tome una FOTO de SU ROSTRO manteniendo un gesto NEUTRAL",
    },
    image: "assets/img/neutral.png",
  },
  {
    id: 5,
    stepNumber: 3,
    currentStep: false,
    type: "photo",
    name: "selfie",
  },
  {
    id: 6,
    stepNumber: 4,
    currentStep: false,
    type: "guide",
    name: "selfie_gesture",
    content: {
      title: {
        line1: "Foto rostro",
      },
      text: "Procure que haya BUENA ILUMINACIÓN y tome una FOTO de SU ROSTRO manteniendo el gesto",
    },
  },
  {
    id: 7,
    stepNumber: 4,
    currentStep: false,
    type: "photo",
    name: "selfie_gesture",
  },
];

export const getInitialStep = () =>
  INITIAL_STEPS.filter((step) => step.currentStep)[0];

const GESTURE = [
  { code: 103, label: "ABRIENDO LA BOCA", img: "assets/img/open-mouth.png" },
  { code: 106, label: "GUIÑANDO UN OJO", img: "assets/img/wink.png" },
  { code: 108, label: "SONRIENDO" , img: "assets/img/smile.png"},
];

export const getRandomGesture = () =>
  GESTURE[Math.floor(Math.random() * GESTURE.length)];

export const getIdLabelNumber = (stepNumber, currentStepNumber) => {
  if (stepNumber < currentStepNumber) return "step-done";
  if (stepNumber === currentStepNumber) return "step-active";
  if (stepNumber >= currentStepNumber) return "step-undone";
};

export const getIdLabelLine = (stepNumber, currentStepNumber) => {
  if (stepNumber < currentStepNumber) return "step-line-done";
  if (stepNumber >= currentStepNumber) return "step-line-undone";
};
