import { IonButton, IonGrid, IonImg, IonPage, IonText } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import "./styles.css";
import dniFront from "./images/dni-front.png";
import dniBack from "./images/dni-back.png";
import face from "./images/face.png";
import gesture from "./images/gesture.png";

const STEPS = [
  {
    number: 1,
    image: dniFront,
    description: "Escaneo de DNI (de frente)",
  },
  {
    number: 2,
    image: dniBack,
    description: "Escaneo de DNI (de atras)",
  },
  {
    number: 3,
    image: face,
    description: "Foto rostro neutral",
  },
  {
    number: 4,
    image: gesture,
    description: "Foto rosto con un gesto",
  },
];

const Guide = () => {
  const history = useHistory();
  return (
    <IonPage>
      <IonGrid className="guide-container">
        <div className="guide-text-container">
          <IonText color="dark">
            <p style={{ fontWeight: 700, fontSize: "19px" }}>COMENCEMOS</p>
          </IonText>
          <IonText color="dark">
            <p style={{ fontWeight: 700, width: 250, fontSize: "17px", margin: '10px auto'}}>
              Para poder realizar el Onboarding digital necesitamos realizar los
              siguientes pasos
            </p>
          </IonText>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {STEPS.map((step) => (
              <div key={step.number} className="guide-step-container">
                <div className="guide-step-number-container">
                  <span>{step.number}</span>
                </div>
                <div
                  className={`guide-step-image-container ${
                    step.number <= 2 ? "guide-step-image-container-box" : ""
                  }`}
                >
                  <IonImg src={step.image} />
                </div>
                <div className="guide-step-description-container">
                  <IonText color="dark">{step.description}</IonText>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <IonButton
            color="primary"
            fill="solid"
            expand="full"
            style={{ margin: 0, height: "60px", fontSize: "20px" }}
            onClick={() => history.replace("/app/photos")}
          >
            ¡Estoy listo!
          </IonButton>
          <IonText color="dark"></IonText>
        </div>
      </IonGrid>
    </IonPage>
  );
};

export default Guide;
